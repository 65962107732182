<template>
  <b-overlay :show="loading" rounded="sm"
    ><div>
      <vue-good-table
        id="portfolioTable"
        theme
        ref="statementTable"
        :columns="columns"
        :rows="groupArray[reverse]"
        styleClass="vgt-table bordered striped noPaddingTable"
        @on-cell-click="onCellClick"
        :group-options="{
          enabled: true,
          headerPosition: 'top',
        }"
        :row-style-class="rowStyleClassFn"
        :search-options="{
          enabled: true,
          placeholder: 'Search this table',
        }">
        <div slot="table-actions">
          <div class="p-3">
            Group by:
            <select v-model="firstSort" @change="cancelEditFn()">
              <option disabled value>Group by</option>
              <option>Currency</option>
              <option value="Asset_Class">Asset Class</option>
              <option>Year</option>
              <option value="Trade_Date">Trade Date</option>
            </select>
            <b-form-checkbox
              id="checkbox-1"
              v-model="reverse"
              name="checkbox-1"
              @change="cancelEditFn()"
              value="1"
              unchecked-value="0"
              switch>
              Reverse
            </b-form-checkbox>
          </div>
        </div>
        <template slot="table-row" slot-scope="props">
          <!-- No me termina de cerrar ,hay que cambiarlo. else if, else if , else if..... Mucha linea para cambiar un color y mostrar x decimales  -->

          <span
            v-if="props.column.field == 'Principal' && props.row.Principal < 0">
            <!-- Se establece el estilo de la columna "principal" en caso de haber resultdos negativos  -->
            <span style="color: red">
              {{ props.formattedRow[props.column.field] | numFormat("0,0.00") }}
            </span>
          </span>
          <span v-else-if="props.column.type == 'market'">
            {{
              props.formattedRow[props.column.field] | numFormat("0,0.000000")
            }}
          </span>
          <!-- A las columnas que les asigno  distintos type:  les asigno su cantidad de decimales   -->
          <span v-else-if="props.column.type == 'number'">
            {{ props.formattedRow[props.column.field] | numFormat("0,0.00") }}
          </span>
          <span
            v-else-if="props.column.tdClass.includes('redIfNegative')"
            :class="[
              props.row[props.column.field] < 0
                ? 'text-danger'
                : 'text-primary',
            ]">
            {{
              (props.formattedRow[props.column.field] / 100)
                | numFormat("0,0.00%")
            }}
          </span>
          <span
            class="actions"
            inline
            flex
            v-else-if="props.column.field == 'Actions'">
            <router-link
              v-if="props.row.Asset_ID"
              :to="{
                name: 'Edit Position',
                params: { asset_id: props.row.Asset_ID },
              }">
              <b-button
                size="sm"
                class="bg-transparent bordertransparent btn-edit"
                variant="light">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="var(--tableTheme)"
                  class="bi bi-list"
                  viewBox="0 0 16 16">
                  <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                </svg>
              </b-button>
            </router-link>
          </span>
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
      </vue-good-table>
    </div></b-overlay
  >
</template>

<script>
export default {
  name: "PortfolioStatement",
  props: {
    fields: {
      type: Array,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      reverse: 0, //0 :false 1:true
      firstSort: "Asset_Class",
    };
  },
  methods: {
    formatFn_AddPctSymbol(value) {
      value;
      return value + "%";
    },

    onCellClick(param) {
      // Define la función onCellClick y recibe un parámetro "param"
      if (param.column.field === "Asset_Name") {
        // se actualiza el estado de la aplicación llamando a la mutation "SET_AASSETIDENTIFIER"
        // proporcionando como argumento "Asset_Identifier" que trae como valor el identificador del asset
        this.$store.commit("SET_ASSET_IDENTIFIER", param.row.Asset_Identifier);
        // se inicia una action "loadSecurityDataPrice" en el store, relacionado con la carga de datos de cotización para el asset seleccionado
        this.$store.dispatch("loadLastSecurityDataPrice");
        this.$store.dispatch("loadHistoricalSecurityDataPrice");
        this.$store.dispatch("loadSecurityReturnToDate");
        // aqui se redirige a la ruta "SecurityOverview"
        this.$router.push({ name: "SecurityOverview" });
      }
    },

    sumMarketValue(rowObj) {
      let sum = 0;

      for (let i = 0; i < rowObj.children.length; i++) {
        const marketValueUsd = rowObj.children[i].Market_Value_USD;
        // Aca verifico si Market_Value_USD no es null o undefined antes de sumarlo
        if (marketValueUsd !== null && marketValueUsd !== undefined) {
          sum += Number.parseFloat(marketValueUsd);
        }
      }
      const formattedSum = Intl.NumberFormat("en-US", {
        currency: "USD",
        style: "currency",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(sum);

      return formattedSum;
    },

    sumPortfolioPct(rowObj) {
      let sumPortfolioPct = 0;
      for (let i = 0; i < rowObj.children.length; i++) {
        sumPortfolioPct += rowObj.children[i].PortfolioPct;
      }
      return sumPortfolioPct; // no hace falta mandar el simbolo de % porque en column.type = percentage (syntactic sugar de Vue good table )
    },
    rowStyleClassFn(row) {
      return row.footerRow == 1 ? "d-none" : "";
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  computed: {
    assetIdentifier() {
      return this.$store.state.assetIdentifier;
    },

    clientPortfolio() {
      return this.$store.state.PortfolioData;
    },
    loading() {
      return Object.keys(this.clientPortfolio).length == 0;
    },
    lastRow() {
      let marketValueTotal = this.clientPortfolio.reduce(
        (accumulator, asset) => accumulator + Number(asset.Market_Value_USD),
        0
      );
      let footerRow = {
        GrupoUno: "TOTAL NET WORTH",
        children: [
          { Market_Value_USD: marketValueTotal, PortfolioPct: 1, footerRow: 1 },
        ],
      };
      return footerRow;
    },
    dateArray() {
      //Agrego propiedades nuevas a cada objeto,YEAR y PORTFOLIO
      let sum = this.clientPortfolio.reduce(
        (s, a) => s + Number(a.Market_Value_USD),
        0
      ); //misma funcion que la de arriba, deberia sacarla PERO ...(sigo abajo )
      this.clientPortfolio.forEach(function (element) {
        element.Year = element.Trade_Date.split("-")[0];
        element.PortfolioPct = element.Market_Value_USD / sum; // si cambio sum por this.total => Cannot read property 'total' of undefined
      });
      return this.clientPortfolio;
    },
    groupArray() {
      //Aca se cocina la data que traje del api y despues modifique en dateArray,
      Array.prototype.groupBy = function (prop) {
        // Metodo para agrupar objetos segun distintos valores de la propiedad elegida
        return this.reduce(function (groups, item) {
          let val = item[prop];
          groups[val] = groups[val] || [];
          groups[val].push(item);
          return groups;
        }, {});
      };
      let groupedByThing = this.dateArray.groupBy([this.firstSort]); //Primer agrupado(ej: si agrupados por color [rojo:{objetos rojos},azu:{obj azules}])
      let groupWithChildren = Object.entries(groupedByThing).map((element) => {
        //"Cocinado" de cada grupo [grupo:rojo,children:[{objetos rojos}]]
        return { GrupoUno: element[0], children: element[1] };
      });

      let sortedGroup = groupWithChildren.sort((a, b) =>
        a.GrupoUno > b.GrupoUno ? 1 : -1
      );
      let reversedGroup = [...sortedGroup].reverse();
      //Clonado del array anterior, pero con orden inverso
      sortedGroup.push(this.lastRow);
      reversedGroup.push(this.lastRow);
      return [sortedGroup, reversedGroup]; //Las table rows leen esta informacion , el reverse solamente cambia el elemento que se lee
    },
    isMobile() {
      return this.windowWidth <= 567;
    },

    columns() {
      return [
        {
          label: "Asset Name", //string que se muestra en la cima columna
          field: "Asset_Name", // nombre de Propiedad que va en la columna
          headerField: "GrupoUno", //aca de muestra el valor de cada propiedad por la que se agruparon "firstSort:value"
          width: "300px",
          tdClass: "text-left iphone align-middle overflowhiddencell underline",
          thClass: "vgt-center-align align-middle iphone",
          filterOptions: {
            enabled: true,
            placeholder: "Name",
            trigger: "keyup",
          },
        },
        {
          label: "Asset Class",
          field: "Asset_Class",
          hidden: this.firstSort == "Asset_Class",
          type: "string",
          tdClass: "text-center align-middle overflowhiddencell",
          thClass: "vgt-center-align align-middle",
          filterOptions: {
            enabled: true,
            placeholder: "Class",
            trigger: "keyup",
          },
        },
        {
          label: "Ccy",
          field: "Currency",
          type: "string",
          width: "5%",
          hidden: this.firstSort == "Currency",
          tdClass: "text-center iphone align-middle",
          thClass: "vgt-center-align align-middle iphone",
          filterOptions: {
            enabled: true,
            placeholder: "Ccy",
            trigger: "keyup",
          },
        },
        {
          label: "Principal",
          field: "Principal",
          type: "number",
          thClass: "vgt-center-align align-middle",
          tdClass: "align-middle",
          hidden: this.isMobile,
          filterOptions: {
            enabled: true,
            placeholder: "Principal",
            trigger: "keyup",
          },
        },
        {
          label: "Trade Date",
          field: "Trade_Date",
          type: "string",
          width: "120px",
          tdClass: "text-center align-middle",
          thClass: "vgt-center-align align-middle",
          hidden: this.firstSort == "Trade_Date" || this.isMobile,
          filterOptions: {
            enabled: true,
            placeholder: "Date",
            trigger: "keyup",
          },
        },
        {
          label: "Paid Price",
          field: "Paid_Price",
          type: "market",
          tdClass: "text-right align-middle",
          thClass: "vgt-center-align align-middle ",
          hidden: this.isMobile,
          filterOptions: {
            enabled: true,
            placeholder: "Paid Price",
            trigger: "keyup",
          },
        },
        {
          label: "Mkt Price",
          field: "Market_Price",
          type: "market",
          tdClass: "text-right align-middle",
          hidden: this.isMobile,
          thClass: "vgt-center-align align-middle iphone align-middle",
          filterOptions: {
            enabled: true,
            placeholder: "Mkt Price",
            trigger: "keyup",
          },
        },
        {
          label: "Mkt Value (USD)",
          field: "Market_Value_USD",
          headerField: this.sumMarketValue,
          type: "number",
          width: "9%",
          tdClass: "text-right iphone align-middle",
          thClass: "vgt-center-align align-middle iphone",
          filterOptions: {
            enabled: true,
            placeholder: "Mkt Value",
            trigger: "keyup",
          },
        },
        {
          label: "Net Worth (%)",
          field: "PortfolioPct",
          headerField: this.sumPortfolioPct,
          type: "percentage",
          width: "7%",
          hidden: this.isMobile,
          tdClass: "text-right align-middle",
          thClass: "vgt-center-align align-middle",
          filterOptions: {
            enabled: true,
            placeholder: "%",
            trigger: "keyup",
          },
        },
        {
          label: "CPR (FX)",
          field: "HPR_Asset_ccy",
          type: "decimal",
          width: "3%",
          tdClass: "redIfNegative align-middle text-right",
          //formatFn: this.formatFn_AddPctSymbol,
          hidden: this.isMobile,
          thClass: "vgt-center-align align-middle",
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          label: "CPR (USD)",
          field: "HPR_Base_ccy",
          type: "decimal",
          width: "3%",
          tdClass: "align-middle text-right redIfNegative",
          //formatFn: this.formatFn_AddPctSymbol,
          hidden: this.isMobile,
          thClass: "vgt-center-align align-middle",
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          label: "HPR (FX)",
          field: "HPR_FX",
          type: "decimal",
          width: "3%",
          tdClass: "redIfNegative align-middle text-right",
          //formatFn: this.formatFn_AddPctSymbol,
          hidden: this.isMobile,
          thClass: "vgt-center-align align-middle",
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          label: "Yield",
          field: "YTM",
          type: "decimal",
          width: "3%",
          hidden: this.isMobile,
          tdClass: "text-right align-middle",
          formatFn: this.formatFn_AddPctSymbol,
          thClass: "vgt-center-align align-middle",
          filterOptions: {
            enabled: true,
            placeholder: "%",
            trigger: "keyup",
          },
        },
        {
          field: "Actions",
          label: "",
          actions: "actions",
          thClass: "vgt-center-align align-middle",
          tdClass: "text-center",
          html: true,
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style lang="css">
td.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.vgt-row-header {
  background-color: #161a4110 !important;
}

#portfolioTable tr {
  font-size: 95%;
}

#portfolioTable
  > .vgt-inner-wrap
  > .vgt-global-search
  > .vgt-global-search__input {
  padding-top: 10px;
}

.noPaddingTable.vgt-table td {
  padding: 0.2em 0.5em 0.2em 0.5em;
}

.noPaddingTable.vgt-table tbody th {
  padding: 0.5em 0.5em 0.5em 0.5em;
}

.overflowhiddencell {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 340px) {
  .iphone {
    width: 33vw;
    max-width: 107px;
  }
  #portfolioTable {
    font-size: 85%;
  }
}
</style>
