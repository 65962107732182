<template>
  <EditableTable
    :fields="fields"
    :storeData="storeData"
    :storePath="storePath"
    :id_property="id_property"
    :item_name="'Order'"
    :initialSort="[{ field: 'Date', type: 'desc' }]"
    :initialFilter="initialFilter"
    :usesPortfolioSelector="true"/>
</template>
<script>
import ShadowCard from "../../../presentationalComponents/ShadowCard.vue";
import EditableTable from "./EditableTable.vue";

export default {
  name: "orders",
  components: { EditableTable, ShadowCard },
  props: {
    initialFilter: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      items: [],
      storeData: "ORDERS",
      storePath: "orders",
      id_property: "order_id",
      fields: [
        {
          field: "order_id",
          label: "ID",
          needToBeInteger: true,
          thClass: "text-center",
          tdClass: "text-center",
          filterOptions: {
            enabled: true,
            placeholder: "ID",
            trigger: "keyup",
          },
        },
        {
          type: "date",
          field: "date",
          label: "Date",
          tdClass: "text-center",
          thClass: "text-center",
          editable: true,
          formatFn: this.dateFormatFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Date", // placeholder for filter input
            trigger: "keyup", //only trigger on enter not on keyup
          },
        },
        {
          type: "select",
          field: "portfolio_id",
          label: "Portfolio ID",
          options: this.portfolioList,
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          validation: "required",
          filterOptions: {
            enabled: true,
            placeholder: "Portfolio ID",
            trigger: "keyup",
          },
        },
        {
          type: "select",
          label: "Side",
          field: "side",
          options: ["BUY", "SELL", "SELL SHORT"],
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Side", // placeholder for filter input
            trigger: "keyup", //only trigger on enter not on keyup
          },
        },
        {
          type: "select",
          field: "asset_id",
          label: "Asset ID",
          thClass: "text-center",
          tdClass: "text-left",
          options: this.securitiesList,
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Asset ID",
            trigger: "keyup",
          },
        },
        {
          type: "text",
          field: "description",
          label: "Description",
          thClass: "text-center",
          tdClass: "text-left",
          editable: true,
          sumColumn: true,
          filterOptions: {
            enabled: true,
            placeholder: "Description",
            trigger: "keyup",
          },
        },
        {
          type: "select",
          field: "currency_id",
          label: "Ccy",
          options: this.currenciesList,
          tdClass: "text-center",
          thClass: "text-center",
          editable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Ccy", // placeholder for filter input
            trigger: "keyup", //only trigger on enter not on keyup
          },
        },
        {
          type: "number",
          field: "principal",
          label: "Principal",
          summable: true,
          thClass: "text-center",
          editable: true,
          precision: 2,
          filterOptions: {
            enabled: true,
            placeholder: "Principal",
            trigger: "keyup",
          },
        },
        {
          type: "select",
          field: "type",
          label: "Type",
          options: ["MKT", "LMT", "STP"],
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Type",
            trigger: "keyup",
          },
        },
        {
          type: "select",
          label: "TIF",
          field: "tif",
          options: ["DAY", "GTC", "IOC", "FOK", "MOO"],
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "TIF",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          field: "price",
          label: "Price",
          prefix: "$",
          thClass: "text-center",
          tdClass: "text-right",
          summable: true,
          editable: true,
          precision: 4,
          filterOptions: {
            enabled: true,
            placeholder: "Price",
            trigger: "keyup",
          },
        },
        {
          type: "select",
          field: "status",
          label: "Status",
          options: ["EXECUTED", "PENDING", "CANCELLED"],
          thClass: "text-center",
          tdClass: "text-center",
          editable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Status",
            trigger: "keyup",
          },
        },

        {
          html: true,
          field: "Actions",
          label: "Actions",
          actions: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    this.$store.commit(`SET_TABLE_EDIT_MODE`, "false");
    this.setEditableFields();
  },
  methods: {
    updateSelectOptions(fieldName, options) {
      const fieldToUpdate = this.fields.find(
        (field) => field.field === fieldName
      );
      if (fieldToUpdate) {
        fieldToUpdate.options = options;
      }
    },
    dateFormatFn(value) {
      try {
        let formattedDate = new Date(value).toISOString().split("T")[0];

        return formattedDate;
      } catch (error) {
        return value.substring(0, 10);
      }
    },
    addIDs(responseArray) {
      let newArray = responseArray.map((x, i) => {
        (x.item_id = i + 1),
          (x.Date = new Date(x.Date).toISOString().split("T")[0]);
        return x;
      });
      return newArray;
    },
    getOrders() {
      this.isLoading = true;
      getOrders().then((response, error) => {
        this.isLoading = !response.data.status;

        this.$store.commit("SET_TABLE_ORDERS", this.addIDs(response.data.data));
      });
      console.log(this.isLoading);
    },
    setFieldId() {
      this.$store.commit("SET_TABLE_FIELD_ID", this.id_property);
    },
    setEditableFields() {
      this.$store.commit("SET_TABLE_EDITABLEFIELDS", this.editableFields);
    },
  },
  watch: {
    portfolioList: {
      immediate: true,
      handler(newVal) {
        this.updateSelectOptions("portfolio_id", newVal);
      },
    },
    currenciesList: {
      immediate: true,
      handler(newVal) {
        this.updateSelectOptions("currency_id", newVal);
      },
    },
    securitiesList: {
      immediate: true,
      handler(newVal) {
        this.updateSelectOptions("asset_id", newVal);
      },
    },
  },
  computed: {
    editableFields() {
      let editableFields = this.fields.filter((field) => field.editable);

      return editableFields;
    },
    portfolioList() {
      return this.$store.state.portfolioList.map((portfolio) => ({
        value: portfolio.portfolio_id,
        label: portfolio.portfolio_id,
      }));
    },

    currenciesList() {
      return this.$store.state.currenciesList.map((currency) => ({
        value: currency.currency_code,
        label: currency.currency_code + " - " + currency.description,
      }));
    },

    securitiesList() {
      return this.$store.state.securitiesList.map((security) => {
        let identifier = security.figi || security.isin || security.bbg_ticker;
        return {
          value: security.asset_id,
          label: security.name + " (" + security.bbg_ticker + ")",
          attrs: {
            identifier: identifier,
          },
        };
      });
    },
  },
};
</script>
