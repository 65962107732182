<template>
  <ShadowCard
    :param="{
      name: 'Portfolio View',
    }">
    <div v-if="this.selectedPortfolio" class="row">
      <div class="col">
        <PortfolioStatement id="VGT" />
      </div>
    </div>
    <div v-if="this.selectedPortfolio" class="row my-5">
      <div class="col-md-4">
        <YTDPerformance />
      </div>
      <div class="col-md-8">
        <Graphics />
      </div>
    </div>
    <div v-if="this.selectedPortfolio" class="row">
      <div class="col text-center">
        <h5><strong>Pending Orders</strong></h5>
        <Orders :initialFilter="{ status: 'PENDING' }" />
      </div>
    </div>
    <div v-if="!this.selectedPortfolio" class="row">
      <div class="col text-center">
        <p class="mb-0">
            Please select a Portfolio
        </p>
      </div>
    </div>
  </ShadowCard>
</template>

<script>
import ShadowCard from "../../../presentationalComponents/ShadowCard.vue";
import PortfolioStatement from "./PortfolioStatement.vue";
import YTDPerformance from "./YTDPerformance.vue";
import Graphics from "./Graphics.vue";
import Orders from "../account/orders.vue";

export default {
  name: "PortfolioView",
  components: {
    PortfolioStatement,
    YTDPerformance,
    Graphics,
    ShadowCard,
    Orders,
  },
  data() {
    return {
      clientPortfolio: [],
    };
  },

  computed: {
    portfolioData() {
      let data = this.$store.state.PortfolioData;
      this.clientPortfolio = data;
      return data;
    },
    selectedPortfolio() {
      return this.$store.state.selectedPortfolio;
    },
    portfolioPerformanceData() {
      return this.$store.state.portfolio_performance;
    },
  },
};
</script>

<style scoped></style>
