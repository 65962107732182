<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="row">
      <div class="col-md-6">
        <table class="table table-sm">
          <thead class="table-light">
            <tr>
              <th colspan="2" scope="col" class="text-center">
                <h5 class="mb-0"><strong>Currency Distribution</strong></h5>
              </th>
            </tr>
          </thead>
          <tbody>
            <pie-chart
              v-if="!currencyHasNegative"
              :data="currencyDataTopFive"
              :colors="colorPalette"
              thousands=","
              suffix="%"
              round="2"
              title=""
              :messages="{ empty: 'No data' }"></pie-chart>
            <column-chart
              :library="options"
              v-if="currencyHasNegative"
              :data="currencyDataTopFive"
              :colors="[colorPalette]"
              thousands=","
              round="2"
              suffix="%"
              title=""
              :messages="{ empty: 'No data' }"
              :min="minValuecurrencyDataTopFive"
              :max="maxValuecurrencyDataTopFive"></column-chart>
          </tbody>
        </table>
      </div>
      <div class="col-md-6">
        <table class="table table-sm">
          <thead class="table-light">
            <tr>
              <th colspan="2" scope="col" class="text-center">
                <h5 class="mb-0"><strong>Asset Class Distribution</strong></h5>
              </th>
            </tr>
          </thead>
          <tbody>
            <pie-chart
              v-if="!assetClassHasNegative"
              :data="AssetClassDataTopFive"
              :colors="colorPalette"
              thousands=","
              round="2"
              suffix="%"
              title=""
              :messages="{ empty: 'No data' }"></pie-chart>
            <column-chart
              :library="options"
              v-if="assetClassHasNegative"
              :data="AssetClassDataTopFive"
              :colors="[colorPalette]"
              thousands=","
              round="2"
              suffix="%"
              title=""
              :messages="{ empty: 'No data' }"
              :min="minValueAssetClass"
              :max="maxValueAssetClass"></column-chart>
          </tbody>
        </table>
      </div></div
  ></b-overlay>
</template>

<script>
export default {
  name: "Graphics",
  components: {},
  data() {
    return {
      colorPalette: [
        "#B0CC40",
        "#4879B4",
        "#CC2568",
        "#FBBD00",
        "#BFBFE0",
        "#E0642E",
        "#973FFB",
        "#FB4E4E",
      ],
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },
  methods: {
    groupBy(prop) {
      return this.reduce(function (groups, item) {
        const val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {});
    },
    groupAndFormatPortfolioData(grupo) {
      // Extender el prototype de Array con un método personalizado para sumar valores por una propiedad
      Array.prototype.sumarPor = function () {
        let suma = 0;
        // Calcular la suma de los valores de Market_Value_USD
        suma = this.reduce((s, a) => s + Number(a.Market_Value_USD), 0);
        return suma;
      };
      // Crear un array chartData con los valores agrupados y sumados por la propiedad específica (grupo)
      let chartData = Object.entries(this.PortfolioData.groupBy(grupo)).map(
        (element, index) => [
          // Primer elemento: nombre de la propiedad agrupada (assetClass/currency)
          element[0],
          // Segundo elemento: porcentaje del valor total del mercado (Market_Value_USD)
          (element[1].sumarPor() / Math.abs(this.marketValueTotal)) * 100,
        ]
      );

      // Ordenar chartData de mayor a menor valor de Market_Value_USD
      // En este caso, la función de comparación que se le pasa a sort es (a, b) => b[1] - a[1].
      // Esta función compara dos elementos del array chartData (a y b)
      // y devuelve un número negativo si b[1] es mayor que a[1], un número positivo si a[1] es mayor que b[1] y 0 si ambos valores son iguales.
      // se ordenan de mayor a menor en base al valor en la posición 1 de cada elemento (Market_Value_USD).
      // Después de la llamada a sort, el array chartData estará ordenado de mayor a menor en base a Market_Value_USD.
      chartData.sort((a, b) => b[1] - a[1]);
      return chartData;
    },
  },

  computed: {
    loading() {
      return Object.keys(this.PortfolioData).length == 0;
    },
    minValueAssetClass() {
      let minMax = this.AssetClassDataTopFive.reduce(
        (acc, val) => {
          acc.min = Math.min(acc.min, val[1]);
          return acc;
        },
        { min: Infinity }
      );
      return Math.ceil(minMax.min - 5);
    },
    maxValueAssetClass() {
      let minMax = this.AssetClassDataTopFive.reduce(
        (acc, val) => {
          acc.max = Math.max(acc.max, val[1]);
          return acc;
        },
        { max: -Infinity }
      );
      return Math.ceil(minMax.max + 5);
    },
    minValuecurrencyDataTopFive() {
      let minMax = this.currencyDataTopFive.reduce(
        (acc, val) => {
          acc.min = Math.min(acc.min, val[1]);
          return acc;
        },
        { min: Infinity }
      );
      return Math.ceil(minMax.min - 5);
    },
    maxValuecurrencyDataTopFive() {
      let minMax = this.currencyDataTopFive.reduce(
        (acc, val) => {
          acc.max = Math.max(acc.max, val[1]);
          return acc;
        },
        { max: -Infinity }
      );
      return Math.ceil(minMax.max + 5);
    },
    PortfolioData() {
      return this.$store.state.PortfolioData;
    },
    currencyDataTopFive() {
      let data = this.groupAndFormatPortfolioData("Currency");

      if (data.length > 5) {
        let value = 0;
        for (let i = 5; i < data.length; i++) {
          value += data[i][1];
        }
        data = data.slice(0, 5);
        data.push(["Various", value]);
      }

      return data;
    },
    AssetClassDataTopFive() {
      let data = this.groupAndFormatPortfolioData("Short_Asset_Class");

      if (data.length > 5) {
        let value = 0;
        for (let i = 5; i < data.length; i++) {
          value += data[i][1];
        }
        data = data.slice(0, 5);
        data.push(["Various", value]);
      }

      return data;
    },
    currencyHasNegative() {
      let hasNegative = this.currencyDataTopFive.flat().some((v) => v < 0);
      return hasNegative;
    },
    assetClassHasNegative() {
      let assetHasNegative = this.AssetClassDataTopFive.flat().some(
        (v) => v < 0
      );
      return assetHasNegative;
    },
    marketValueTotal() {
      return this.PortfolioData.reduce(
        (accumulator, asset) => accumulator + Number(asset.Market_Value_USD),
        0
      );
    },
  },
};
</script>
