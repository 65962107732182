<template>
  <b-overlay :show="loading" rounded="sm">
    <table class="table table-striped table-bordered table-sm">
      <thead class="table-light">
        <tr>
          <th colspan="2" scope="col" class="text-center">
            <h5 class="mb-0"><strong>YTD Performance</strong></h5>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" class="font-weight-normal align-middle">
            Net Worth at <br class="d-block d-md-none" />{{
              portfolioPerformanceData.BalanceDate
            }}
          </th>
          <td scope="row" class="text-right align-middle">
            {{
              portfolioPerformanceData.InitialNetWorth | numFormat("$0,0.00")
            }}
          </td>
        </tr>
        <tr>
          <th scope="row" class="font-weight-normal align-middle">
            Transfers IN / OUT
          </th>
          <td scope="row" class="text-right align-middle underline">
            <router-link :to="{ name: 'transfers' }">
              {{
                portfolioPerformanceData.TransfersInOut | numFormat("$0,0.00")
              }}
            </router-link>
          </td>
        </tr>
        <tr>
          <th scope="row" class="font-weight-normal align-middle">
            Current Net Worth
          </th>
          <td scope="row" class="text-right align-middle">
            {{
              portfolioPerformanceData.CurrentNetWorth | numFormat("$0,0.00")
            }}
          </td>
        </tr>
        <tr class="p-3 mb-2 bg-secondary text-white">
          <th scope="row" class="font-weight-normal align-middle">
            Profit & Loss In Capital
          </th>
          <td scope="row" class="text-right align-middle">
            {{
              portfolioPerformanceData.ProfitAndLossInCapital
                | numFormat("$0,0.00")
            }}
          </td>
        </tr>
        <tr class="p-3 mb-2 bg-secondary text-white">
          <th scope="row" class="font-weight-normal align-middle">
            Time Weighted Return
          </th>
          <td scope="row" class="text-right align-middle">
            {{
              portfolioPerformanceData.TimeWeightedReturn | numFormat("0.00")
            }}%
          </td>
        </tr>
        <tr class="p-3 mb-2 bg-secondary text-white">
          <th scope="row" class="font-weight-normal align-middle">
            Money Weighted Return
          </th>
          <td scope="row" class="text-right align-middle">
            {{
              portfolioPerformanceData.MoneyWeightedReturn | numFormat("0.00")
            }}%
          </td>
        </tr>
        <tr class="p-3 mb-2 bg-secondary text-white">
          <th scope="row" class="font-weight-normal align-middle">
            Annualized Return
          </th>
          <td scope="row" class="text-right align-middle">
            {{ portfolioPerformanceData.AnnualizedReturn | numFormat("0.00") }}%
          </td>
        </tr>
      </tbody>
    </table>
  </b-overlay>
</template>

<script>
export default {
  name: "YTDPerformance",

  computed: {
    portfolioPerformanceData() {
      return this.$store.state.portfolio_performance;
    },

    loading() {
      return Object.keys(this.portfolioPerformanceData).length == 0;
    },
  },
};
</script>
