var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('div',[_c('vue-good-table',{ref:"statementTable",attrs:{"id":"portfolioTable","theme":"","columns":_vm.columns,"rows":_vm.groupArray[_vm.reverse],"styleClass":"vgt-table bordered striped noPaddingTable","group-options":{
        enabled: true,
        headerPosition: 'top',
      },"row-style-class":_vm.rowStyleClassFn,"search-options":{
        enabled: true,
        placeholder: 'Search this table',
      }},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'Principal' && props.row.Principal < 0)?_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm._f("numFormat")(props.formattedRow[props.column.field],"0,0.00"))+" ")])]):(props.column.type == 'market')?_c('span',[_vm._v(" "+_vm._s(_vm._f("numFormat")(props.formattedRow[props.column.field],"0,0.000000"))+" ")]):(props.column.type == 'number')?_c('span',[_vm._v(" "+_vm._s(_vm._f("numFormat")(props.formattedRow[props.column.field],"0,0.00"))+" ")]):(props.column.tdClass.includes('redIfNegative'))?_c('span',{class:[
            props.row[props.column.field] < 0
              ? 'text-danger'
              : 'text-primary',
          ]},[_vm._v(" "+_vm._s(_vm._f("numFormat")((props.formattedRow[props.column.field] / 100),"0,0.00%"))+" ")]):(props.column.field == 'Actions')?_c('span',{staticClass:"actions",attrs:{"inline":"","flex":""}},[(props.row.Asset_ID)?_c('router-link',{attrs:{"to":{
              name: 'Edit Position',
              params: { asset_id: props.row.Asset_ID },
            }}},[_c('b-button',{staticClass:"bg-transparent bordertransparent btn-edit",attrs:{"size":"sm","variant":"light"}},[_c('svg',{staticClass:"bi bi-list",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"var(--tableTheme)","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"}})])])],1):_vm._e()],1):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticClass:"p-3"},[_vm._v(" Group by: "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstSort),expression:"firstSort"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.firstSort=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.cancelEditFn()}]}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Group by")]),_c('option',[_vm._v("Currency")]),_c('option',{attrs:{"value":"Asset_Class"}},[_vm._v("Asset Class")]),_c('option',[_vm._v("Year")]),_c('option',{attrs:{"value":"Trade_Date"}},[_vm._v("Trade Date")])]),_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":"1","unchecked-value":"0","switch":""},on:{"change":function($event){return _vm.cancelEditFn()}},model:{value:(_vm.reverse),callback:function ($$v) {_vm.reverse=$$v},expression:"reverse"}},[_vm._v(" Reverse ")])],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }